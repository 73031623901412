import React, { useState, useEffect, Fragment } from "react";
import {
  Stack,
  Button,
  Typography,
  Grid,
  Paper,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  styled,
} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import "../data/term.json";
import { useParams, useNavigate } from "react-router-dom";
import { Buffer } from 'buffer';
import VerifyQR from "../Pages/VerifyQR";

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Payment = (props) => {
 
  var termDB = require('../data/term.json');
  const queryParams = useParams();
  const navigate = useNavigate();
 
  const _listRegistrationEndpoint = process.env.REACT_APP_PGW_LIST_REGISTRATION;
  const _createPaymentEndpoint = process.env.REACT_APP_PGW_CREATE_PAYMENT;
  
  const [displayUI, setDisplayUI] = useState(true);
  const [paymentCode, setPaymentCode] = useState({})
  const [payloadData, setPayloadData] = useState({
    merchant: "",
    ref1: "",
    ref2: "",
    amount : 0,
    description: "",
    returnURL : ""
  });

  const [isQR, setIsQR] = useState(false);
  const [isDebug, setIsDebug] = useState(false);

  const [openCreditCardDialog, setOpenCreditCardDialog] = useState(false);
  const [openCreditCardDialogBay, setOpenCreditCardDialogBay] = useState(false);
  const [openFirstChoice, setOpenFirstChoice] = useState(false);

  useEffect(()=>{
      /*
        Parse base64 string from "Token" parameter into JSON of these following formats
        {"merchant" : "SIS-HDY","ref1" : "BAC202212221","ref2" : "6210210111","description" : "Tuition Fee",
         "returnURL": "https://sis.psu.ac.th","amount":14400}
      */
      //Decode Token
      let token = props.token;
      let tokenResult = JSON.parse(Buffer.from(token.toString(), "base64").toString());

      setIsDebug(tokenResult.debug);
      setPayloadData(tokenResult);


      let paymentCodeBAY = "";
      let paymentCodeSCB = "";


      setIsQR(props.qr);

      axios.get(_listRegistrationEndpoint+"?code="+tokenResult.merchant).then((res)=>{
        let msg = res.data;
       
        for(let i = 0;i < msg.data.length;i++){
            if (msg.data[i].externalMerchant.epgwName =="BAY"){
                paymentCodeBAY = msg.data[i]["code"]
            }else if (msg.data[i].externalMerchant.epgwName =="SCB-2C2P"){
                paymentCodeSCB = msg.data[i]["code"]
            } 
        }
        setPaymentCode( { 
            "BAY" : paymentCodeBAY,
            "SCB" : paymentCodeSCB
        })
      }).catch((err)=>{
        //console.log(err)
        alert("พบปัญหาในการเชื่อมต่อกับระบบ Payment Gateway กรุณาทดลองใหม่ในภายหลัง")
        window.location = payloadData.returnURL;
        //TODO : Redirect to Cancel
      })    
  },[])



  
  const _retryPostRequest = async (url, payload,  maxRetries, retries = 0) => {
        let result = null
        if(retries < maxRetries){
            result = await axios.post(url,payload)
            .catch(error => {
                retries++
                _retryPostRequest(url, maxRetries, retries)
                console.log("Retry number: " + retries)
            });

            if (result.data.statusCode == "-1") {
              retries++
              _retryPostRequest(url, maxRetries, retries)
            }
        }

        if (retries >= maxRetries) return -1;
        else return result;
  }


   

     
  const createQRPayment = async (merchantCode) => {
   
    
    let totalPrice = payloadData.amount;
    let payload = {
        "rawAmount" : payloadData.amount, 
        "amount" : payloadData.amount,
        "ref1" : payloadData.ref1,
        "ref2" : payloadData.ref2.replace("-",""),
        "description": "Tuition Fee",
        "registration" : merchantCode,
        "language": "TH"
    }  


    function isImage(data){
      let knownTypes = {
        '/': 'data:image/jpg;base64,',
        'i': 'data:image/png;base64,',
        /*ETC*/
        }
        
        let image = new Image()
        
        if(!knownTypes[data[0]]){
          console.log("encoded image didn't match known types");
          return false;
        }else{
          image.src = knownTypes[0]+data
          image.onload = function(){
            //This should load the image so that you can actually check
            //height and width.
            if(image.height === 0 || image.width === 0){
              console.log('encoded image missing width or height');
              return false;
            }
        }
        return true;
      }
    }

    let res = await _retryPostRequest(_createPaymentEndpoint, payload, 5);
    if (res == -1) {
      alert("พบข้อผิดพลาดในการเชื่อมต่อกับระบบธนาคาร กรุณาตรวจสอบการเชื่อมต่อ Internet หรือทดลองทำรายการใหม่ในภายหลัง")
    }else {
        let msg = res.data;
        if ((msg != "-1") && (isImage(msg.data.qrImage))){
          localStorage.clear();
          localStorage.setItem("payQRRef", JSON.stringify(msg.data)); 
          localStorage.setItem("returnURL", payloadData.returnUrl);
          localStorage.setItem("QRDescription", payloadData.description);
          localStorage.setItem("StudentID", payloadData.ref2.slice(3));
          


          setDisplayUI(false);
          navigate('/qr');
        }else {
          alert("พบข้อผิดพลาดในการเชื่อมต่อกับระบบธนาคาร กรุณาตรวจสอบการเชื่อมต่อ Internet หรือทดลองทำรายการใหม่ในภายหลัง")
        }
    }
 
    // workaround : retry createPaymentEndpoint at least 5 times before throw error
    /*let i = 0;
    var success = false;
    while ( (!success) && (i < 5) ) {
      axios.post(_createPaymentEndpoint, payload).then( (res)=> {
      let msg = res.data;
      if (msg.statusCode == "-1") {
       
      }else {
        localStorage.clear();
        localStorage.setItem("payQRRef", JSON.stringify(msg.data)); 
        localStorage.setItem("returnURL", payloadData.returnUrl);
        setDisplayUI(false);
        success = true;
        navigate('/qr');
      }
      alert("Try "+i+" "+success);
    });
    
    i++;
  }

  if (success == false) {
    alert("พบปัญหาในการเชื่อมต่อกับธนาคาร กรุณาตรวจสอบการเชื่อมต่ออินเตอร์เน็ทของคุณหรือทดลองทำรายการใหม่ภายหลัง");
  } */


}


const postForm = (path, params, method) => {
  method = method || 'post';

  var form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', path);

  for (var key in params) {
      if (params.hasOwnProperty(key)) {
          var hiddenField = document.createElement('input');
          hiddenField.setAttribute('type', 'hidden');
          hiddenField.setAttribute('name', key);
          hiddenField.setAttribute('value', params[key]);

          form.appendChild(hiddenField);
      }
  }
  
  document.body.appendChild(form);
  form.submit();
}

//postForm('mysite.com/form', {arg1: 'value1', arg2: 'value2'});

  const createPayment = (merchantCode, feeInclude, feeAmount, vatAmount) => {
   
      let denomFee = 1;

      if (feeInclude) {
        denomFee = 1 - (1.07 * 1.3 / 100);
      }
      let totalPrice = payloadData.amount;
      if (feeInclude) {
        totalPrice = (payloadData.amount / denomFee)+0.01;
      }

      let payload = {
          "rawAmount" : payloadData.amount, 
          "amount" : totalPrice.toFixed(2),
          "ref1" : payloadData.ref1,
          "ref2" : payloadData.ref2,
          "description": "Tuition Fee",
          "registration" : merchantCode
      }  

     
      
      axios.post(_createPaymentEndpoint, payload).then( (res)=> {
        let msg = res.data;
        if (msg.statusCode == "-1") {
          alert("พบปัญหาในการเชื่อมต่อกับธนาคาร กรุณาทดลองใหม่ในภายหลัง")
        }else {
          //alert(JSON.stringify(msg.data));
          localStorage.clear();
          localStorage.setItem("payRef", msg.data.ref); 
          localStorage.setItem("returnURL", payloadData.returnUrl);
        
          //window.location = msg.data.redirectUrl;
         if (!merchantCode.includes("SCB")) {
            msg.data.postData.LANGUAGE = "EN";
            postForm(msg.data.redirectUrl, msg.data.postData, 'post');
          }else
         {
             window.location = msg.data.redirectUrl;
         }
          //alert(JSON.stringify(msg.data.postData));
          
        }
      }).catch( (err) => {
        alert("พบปัญหาในการเชื่อมต่อกับธนาคาร กรุณาทดลองใหม่ในภายหลัง ");
      })

  }

  //Credit-card all bank
  const handleClickCreditCardDialogOpen = () => {
    setOpenCreditCardDialog(true);
  };

  const handleClickCreditCardBayDialogOpen = () => {
    setOpenCreditCardDialogBay(true);
  };

  const handleClickCreditCardBayDialogClose = () => {
    setOpenCreditCardDialogBay(false);
  };

  const handleQRPromptpay = () => {
    createQRPayment(payloadData.merchant+"-SCBQR");
  };
  

  const handleCreditCardDialogClose = () => {
    setOpenCreditCardDialog(false);
  };
  const handleAgreeCreditCardDialog = () => {
      //console.log("Pay with "+ JSON.stringify(paymentCode) );
      createPayment(paymentCode["SCB"], true, 1.3, 7 );
  };

  const handleAgreeCreditCardBayDialog = () => {
    //console.log("Pay with "+ JSON.stringify(paymentCode) );
    createPayment(paymentCode["BAY"], false, 0, 0 );
};


  //Credit-card first choice
  const handleClickFirstChoicePhuketOpen = () => {
    setOpenFirstChoice(true);
  };
  const handleFirstChoiceClose = () => {
    setOpenFirstChoice(false);
  };
  const handleAgreeFirstChoicePhuket = () => {
    window.location = "https://payment.phuket.psu.ac.th";
  };


  return (
    <Fragment>


      <Routes>
         <Route exact path="/qr" element={ <VerifyQR  /> } /> 
      </Routes>

      { displayUI && 
          <Stack>
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Stack sx={{ width: "50%" }}>
                <Stack sx={{ marginBottom: "20px" }} alignItems="center">
                  <img src="/psu1.png" style={{ width: "150px" }} />
                </Stack>
                <Typography variant="h5" component="h5" alignSelf="center">
                  PSU Payment Gateway
                </Typography>
                <Typography variant="h6" component="h6">
                  Payment Info
                </Typography>
                <Paper variant="outlined" sx={{ padding: 5 }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                      <Grid item xs={12} md={12} fullWidth>
                      <Typography component="subtitle1" variant="subtitle1">
                        Payment Description  (รายการที่กำลังดำเนินการชำระเงิน)
                      </Typography>
                      <Button variant="contained" disabled fullWidth>
                        {payloadData?.description}
                      </Button>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography component="subtitle1" variant="subtitle1">
                        REF1
                      </Typography>
                      <Button variant="contained" disabled fullWidth>
                        {payloadData?.ref1}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6} fullWidth>
                      <Typography component="subtitle1" variant="subtitle1">
                        REF2
                      </Typography>
                      <Button variant="contained" disabled fullWidth>
                        {payloadData?.ref2}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6} fullWidth>
                      <Typography component="subtitle1" variant="subtitle1">
                        Merchant Code
                      </Typography>
                      <Button variant="contained" disabled fullWidth>
                        {payloadData?.merchant}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6} fullWidth>
                      <Typography component="subtitle1" variant="subtitle1">
                        Payment Amount (in THB)
                      </Typography>
                      <Button variant="contained" disabled fullWidth>
                        {payloadData?.amount}
                      </Button>
                    </Grid>

                  

                  </Grid>
                </Paper>
              </Stack>

              <Stack sx={{ width: "50%", marginTop: 5 }}>
                <Typography variant="h5" component="h5" alignSelf="center">
                    Payment Method
                </Typography>
                <Typography variant="h6" component="h6">
                    Select Your Payment Method
                </Typography>
                <Paper variant="outlined" sx={{ padding: 5 }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                      {!isQR &&  
                        
                        <Grid item xs={12} md={6}>
                          <Button
                            fullWidth
                            startIcon={<CreditCardIcon />}
                            onClick={handleClickCreditCardDialogOpen}
                          >
                            Credit Card (All Banks) : Powered by SCB 2C2P Gateway
                          </Button>
                        </Grid>
                        
                      }

                    

                      {isQR &&  
                        <Grid item xs={12} md={6}>
                          <Button
                            fullWidth
                            startIcon={<CreditCardIcon />}
                            onClick={handleQRPromptpay}
                          >
                            Proceed with Thai QR Payment (All Banks)
                          </Button>
                        </Grid>
                      }

                      { (payloadData.merchant == "SIS-PKT" && !isQR) &&

                          <Grid item xs={12} md={6}>
                          <Button
                            fullWidth
                            startIcon={<CreditCardIcon />}
                            onClick={handleClickCreditCardBayDialogOpen}
                          >
                            Credit Card Krungsri Gateway (First Choice Card Accepted,  0% for 3 or 6 months for Krungsri Card)
                          </Button>
                        </Grid>
                        }
                  </Grid>
                  
                </Paper>
              </Stack>
            </Box>


            <BootstrapDialog
              onClose={handleClickCreditCardBayDialogClose}
              aria-labelledby="customized-dialog-title"
              open={openCreditCardDialogBay}
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClickCreditCardBayDialogClose}
              >
                ข้อตกลงในการใช้งาน
              </BootstrapDialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                { termDB["bay"] }
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClickCreditCardBayDialogClose}>
                  Cancel
                </Button>
                <Button
                  autoFocus
                  onClick={handleAgreeCreditCardBayDialog}
                  variant="contained"
                >
                  Agree
                </Button>
              </DialogActions>
            </BootstrapDialog>

            <BootstrapDialog
              onClose={handleCreditCardDialogClose}
              aria-labelledby="customized-dialog-title"
              open={openCreditCardDialog}
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleCreditCardDialogClose}
              >
                ข้อตกลงในการใช้งาน
              </BootstrapDialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                { termDB["scb"] }
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleCreditCardDialogClose}>
                  Cancel
                </Button>
                <Button
                  autoFocus
                  onClick={handleAgreeCreditCardDialog}
                  variant="contained"
                >
                  Agree
                </Button>
              </DialogActions>
            </BootstrapDialog>
            <BootstrapDialog
              onClose={handleFirstChoiceClose}
              aria-labelledby="customized-dialog-title"
              open={openFirstChoice}
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleFirstChoiceClose}
              >
                ข้อตกลงในการใช้งาน
              </BootstrapDialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                  { termDB["firstChoicePhuket"] }
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleFirstChoiceClose}>
                  Cancel
                </Button>
                <Button
                  autoFocus
                  onClick={handleAgreeFirstChoicePhuket}
                  variant="contained"
                >
                  Agree
                </Button>
              </DialogActions>
            </BootstrapDialog>
          </Stack>
    }
    </Fragment>
  );
};

export default Payment;
