import { Stack, Button, Typography, Box, Avatar } from "@mui/material";
import { green } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Image } from "@mui/icons-material";

const VerifyQR = () => {
  const navigate = useNavigate();
  let _endPointVerifyPayment = process.env.REACT_APP_PGW_VERIFY;
  const [timeLeft, setTimeLeft] = useState(60 * 5);
  const [imgData, setImgData] = useState("");

  const [qrDescription, setQRDescription] = useState("");
  const [studentID, setStudentID] = useState("");


  useEffect(() => {

    let payRef = JSON.parse(localStorage.getItem("payQRRef"));

    setQRDescription(localStorage.getItem("QRDescription"));
    setStudentID(localStorage.getItem("StudentID"));

    setImgData("data:image/jpeg;base64," + payRef.qrImage);

    const interval = setInterval(() => {
      setTimeLeft(timeLeft - 1);
      if (timeLeft > 0) {
        axios.get(_endPointVerifyPayment + "?ref=" + payRef.ref).then((res) => {
          let msg = res.data;
          if (msg.data && msg.data.redirectUrl) {
            window.location = msg.data.redirectUrl
          } else if (msg.statusCode == 1 && msg.data.succeed) {
            navigate("/success")
          }
        });
      } else {
        navigate("/cancel")
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timeLeft])

  return (
    <Fragment>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >

        <Typography
          variant="h5"
          component="h5"
          sx={{ mt: 2, alignItems: "center" }}
          fontWeight="bold"
        >
          แสกนจ่าย QR Code ด้านล่างนี้โดยอาศัย Mobile Banking Application ของท่านได้ทุกธนาคาร
        </Typography>

        <Typography
          variant="h5"
          component="h5"
          sx={{ mt: 2, alignItems: "center", marginBottom: "50px" }}
          fontWeight="bold"
        >
          Please scan the following QR Code to pay with Thailand Promptpay QR Payment
        </Typography>


        <img src={imgData} />

        <Typography
          variant="h5"
          component="h5"
          sx={{ mt: 3, marginTop: "50px" }}
          fontWeight="bold"
        >
          Payment Detail : {qrDescription} <br />
          This QR can be used <Typography component="span" variant="h5" sx={{ color: "red" }}>only</Typography> for student with ID : {studentID}
        </Typography>

        <Typography
          variant="h4"
          component="h4"
          sx={{ mt: 3 }}
          fontWeight="bold"
        >
          Time left :  {timeLeft} seconds
        </Typography>

        <Button
          sx={{ mt: 10, width: "150px" }}
          variant="contained"
          size="small"
          onClick={() => {
            navigate("/cancel");
          }}
        >
          Cancel Payment
        </Button>
      </Box>
    </Fragment>
  );
};

export default VerifyQR;
