import { FormattedMessage } from 'react-intl';
import { useEffect } from 'react';

const MobileQRPayment = ({ imgData, payloadData, handleOptionSelect, locale, toggleDropdown, isOpen, options, captureScreen, dropdownMobileRef }) => {

    return (
        <div className="w-full h-full px-[25px] py-5 space-y-4 md:hidden">
            {/* Logo Destination */}
            <div className="flex items-center justify-center">
                <img src="/PSU-Payment-Gateway.png" style={{ width: "150px" }} />
            </div>
            {/* QR Code */}
            <div className="flex flex-col items-center justify-center">
                <div id="qr-code-container" className=" bg-white rounded-[10px] max-w-[200px] max-h-[200px] flex items-center justify-center shadow-md">
                    <img className="p-[5px]" src={imgData} />
                </div>
                {/* <div className="text-center">
                    <div className="text-[#D02B2D] flex flex-row text-[12px] font-medium items-center justify-center pt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <FormattedMessage id="expire_qr_code" defaultMessage="This QR code will expire in" />
                    </div>
                    <div className="text-[#D02B2D] text-[16px] font-medium">25/06/67  13 : 25 : 56</div>
                </div> */}
            </div>
            {/* Language and Download */}
            <div className="grid grid-cols-2 gap-4">
                <div className="w-full relative inline-block">
                    <div
                        className="flex flex-row justify-between bg-white w-full px-3 py-1.5 cursor-pointer rounded-[10px] shadow-md items-center"
                        onClick={toggleDropdown}
                    >
                        <div className="font-bold">{locale ? locale.toUpperCase() : ''}</div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className={`size-4 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                    </div>
                    {isOpen && (
                        <div ref={dropdownMobileRef} className="font-bold absolute w-full mt-1 bg-white rounded-md shadow-md">
                            {options.map((option) => (
                                <div
                                    key={option}
                                    className={`px-3 py-2 cursor-pointer rounded-[10px] ${option === locale
                                        ? 'bg-[#143C6D] text-white'
                                        : 'hover:bg-gray-100'
                                        }`}
                                    onClick={() => handleOptionSelect(option)}
                                >
                                    {option.toUpperCase()}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="w-full bg-white py-1.5 px-3 rounded-[10px] shadow-md flex flex-row items-center space-x-2 justify-center" onClick={() => captureScreen()}>
                    <div className="font-bold">
                        <FormattedMessage id="download" defaultMessage="Download" />
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                    </div>
                </div>
            </div>
            {/* <div id="raw-amout" className="text-white w-full rounded-[10px] h-[50px] bg-gradient-to-r from-[#143C6D] to-[#3E91F9] flex flex-row justify-center items-center space-x-2 font-bold text-[20px] shadow-md">
                <div>16,010.00</div>
                <div>THB</div>
            </div> */}
            {/* Detail Payment */}
            <div id="detail-payment" className="w-full bg-white rounded-[10px] shadow-md py-3 px-5">
                <div className="font-bold text-[18px] pb-2 flex flex-row space-x-1 items-center">
                    <div>
                        <FormattedMessage id="payment_detail" defaultMessage="Payment Detail" />
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                    </svg>
                </div>
                {/* <div className="py-2 border-b border-t border-[#e5e5e5]">
                    <div className="pl-4 text-[14px]">
                        <FormattedMessage id="pay_to_account" defaultMessage="Pay to account" />
                    </div>
                    <div className="pl-4 text-[16px] font-bold">PSU College of Computing</div>
                </div> */}
                <div className="py-2 border-b border-[#e5e5e5]">
                    <div className="pl-4 text-[14px]">
                        <FormattedMessage id="ref_one" defaultMessage="Ref1" />
                    </div>
                    <div className="pl-4 text-[16px] font-bold">{payloadData.ref1}</div>
                </div>
                <div className="py-2 border-b border-[#e5e5e5]">
                    <div className="pl-4 text-[14px]">
                        <FormattedMessage id="ref_two" defaultMessage="Ref2" />
                    </div>
                    <div className="pl-4 text-[16px] font-bold">{payloadData.ref2}</div>
                </div>
                <div className="py-2 border-b border-[#e5e5e5]">
                    <div className="pl-4 text-[14px]">
                        <FormattedMessage id="description" defaultMessage="Description" />
                    </div>
                    <div className="pl-4 text-[16px] font-bold">{payloadData.description}</div>
                </div>
                {/* <div className="pt-2 w-full flex justify-center">
                    <img src="/psu1.png" style={{ width: "150px" }} />
                </div> */}
            </div>

        </div>
    );
};

export default MobileQRPayment;